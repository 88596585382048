<template>
   <div >
       <div>请确认已收到返修单  {{receivinggoods.repairOrders}}  的部件</div>
       <div class="confirmcolor">确认后所示返修部件将自动入库</div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmReceiptDialog',
  props: ['receivinggoods'],
  data: function () {
    return {
      form: {}
    }
  },
  mounted () {
    console.log('receivinggoods', this.receivinggoods)
  }
}
</script>

<style scoped lang="scss">
.confirmcolor{
    color: #FF6633;
    margin: 20px;
}
</style>
